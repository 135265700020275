<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTowerBroadcast, faToggleOff, faToggleOn, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import 'dayjs/locale/de'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useI18n } from 'petite-vue-i18n'
import { computed, watch } from 'vue'

const { t } = useI18n()

const { locale } = useI18n()
dayjs.extend(relativeTime)

const props = defineProps({
  installation: { type: Object, required: true },
  recentTimestamp: { type: String, default: null }
})
const emit = defineEmits(['selected'])

const messageStatusColorClass = cuComponent => {
  if (!props.recentTimestamp) {
    return 'has-text-grey-lighter'
  }

  const date = cuComponent.current_cu_device.last_message_date

  return date && date >= props.recentTimestamp
    ? 'has-text-success'
    : (cuComponent.lora === false ? 'has-text-grey-lighter' : 'has-text-danger')
}

const messageStatusTimeDescripton = cuComponent => {
  if (!cuComponent.current_cu_device.last_message_date) {
    if (cuComponent.lora === false) {
      return t('installations.tooltip.lora_connection_disabled')
    }
    return t('installations.tooltip.no_recent_messages')
  }

  const date = cuComponent.current_cu_device.last_message_date
  const dateExpired = date && props.recentTimestamp && date < props.recentTimestamp
  if (dateExpired && cuComponent.lora === false) {
    return t('installations.tooltip.lora_connection_disabled')
  }

  return dayjs(cuComponent.current_cu_device.last_message_date).fromNow()
}

const getStatusTagClass = status => {
  if (status === 'maintenance') {
    return 'tag is-success'
  } else if (status === 'installation') {
    return 'tag is-info'
  } else if (status === 'remove_heads_tubes' || status === 'remove_complete_inst') {
    return 'tag is-warning'
  } else {
    return 'tag'
  }
}

const hasPhoto = computed(() => props.installation.photos.length > 0)
const installationPhotoUrl = computed(() => {
  if (!hasPhoto.value) {
    return '/assets/installation-placeholder.png'
  }

  return '/api/installations/photo/' + encodeURIComponent(props.installation.photos[0].id) + '/thumbnail'
})

const onSelect = () => { emit('selected') }

watch(
  () => locale,
  () => dayjs.locale(locale.value),
  { immediate: true }
)
</script>

<template>
  <div class="list-item">
    <div class="list-item-image">
      <a @click="onSelect">
        <figure class="image is-64x64">
          <img
            :src="installationPhotoUrl"
            loading="lazy"
          >
        </figure>
      </a>
    </div>
    <div class="list-item-content">
      <div class="list-item-title is-flex is-justify-content-space-between">
        <span>
          {{ props.installation.work_area?.name }}
          <span
            v-if="installation.standby === true"
            class="icon has-text-warning"
            :title="t('installations.tooltip.standby')"
          >
            <FontAwesomeIcon :icon="faToggleOff" />
          </span>
          <span
            v-if="installation.standby === false"
            class="icon has-text-success"
          >
            <FontAwesomeIcon :icon="faToggleOn" />
          </span>
        </span>
        <span>
          <span>{{ installation.record_number }}</span>
          <span
            v-for="cuComponent of installation.cu_components"
            :key="cuComponent.no"
          >
            <span
              v-if="cuComponent.current_cu_device"
              class="pl-2"
              :class="messageStatusColorClass(cuComponent)"
              :title="messageStatusTimeDescripton(cuComponent)"
            >
              <FontAwesomeIcon :icon="faTowerBroadcast" />
            </span>
          </span>
        </span>
      </div>
      <div class="list-item-description">
        <div v-if="installation.location">
          {{ installation.location }}
        </div>
        <div>
          <span :class="getStatusTagClass(installation.status)">
            {{ t(`installations.status.${installation.status}`) }}
          </span>
          <span v-if="installation.serial">
            <span> &#x02022; </span>
            {{ t('installations.table_header.serial_short') }}
            {{ installation.serial }}
          </span>
        </div>
      </div>
    </div>
    <div class="list-item-controls">
      <div
        class="buttons ml-2 is-right"
      >
        <button
          type="button"
          class="button is-light"
          @click="onSelect"
        >
          <span class="icon is-small">
            <FontAwesomeIcon :icon="faAngleRight" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
