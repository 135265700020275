<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'petite-vue-i18n'
import { form, error, signingIn, signInWithUsernamePassword, setEmail, setPassword } from '../../store/signinPage'
import { useRouter } from 'vue-router'
import { customerUrlSlug } from '../../store/user'

const { t } = useI18n()
const router = useRouter()

const Mode = { LOGIN_USERNAME_PASSWORD: 'login_username_password', NO_CREDENTIALS: 'no_credentials' }

const state = ref({
  mode: Mode.LOGIN_USERNAME_PASSWORD,
  initialized: false
})

const noCredentialsMessageParts = computed(() => {
  const text = t('sign_in.text.no_credentials', { support_email: '{support_email}', support_phone: '{support_phone}' })

  const parts = []

  let remainder = text
  while (remainder !== '') {
    const i = remainder.indexOf('{')
    const j = i !== -1 ? remainder.indexOf('}', i + 1) : -1

    if (i === -1 || j === -1) {
      // no (complete) tag in text
      parts.push({ is: 'span', content: remainder })
      remainder = ''
      continue
    }

    if (i > 0) {
      parts.push({ is: 'span', content: remainder.substring(0, i) })
    }

    const tag = remainder.substring(i, j + 1)

    if (tag === '{support_email}') {
      parts.push({ is: 'a', content: t('common.url.support_email.label'), href: t('common.url.support_email.href') })
    } else if (tag === '{support_phone}') {
      parts.push({ is: 'a', content: t('common.url.support_phone.label'), href: t('common.url.support_phone.href'), className: { 'app-nowrap': true } })
    }

    remainder = remainder.substring(j + 1)
  }

  return parts
})

const submitUsernamePassword = () => {
  signInWithUsernamePassword()
    .then(() => {
      if (customerUrlSlug.value) {
        router.push({ name: 'installations', params: { customerUrlSlug: customerUrlSlug.value } })
      }
    })
}
</script>

<template>
  <div class="x-overlay-page">
    <div class="x-overlay-page-background" />
    <div class="x-overlay-page-content">
      <div class="">
        <div class="box p-0">
          <div class="columns">
            <div
              class="column is-4 is-hidden-touch app-has-yellow-background is-align-content-stretch p-6"
              style="background-color: #EFFF3D;"
            >
              <img
                src="../../../assets/railpartner-brand.svg"
                width="100%"
              >
            </div>
            <div class="column is-12 is-8-desktop app-content-pane">
              <div class="is-hidden-desktop mb-6 app-has-yellow-background p-4">
                <img
                  src="../../../assets/railpartner-brand.svg"
                  width="100%"
                  style="max-height: 10em;"
                >
              </div>
              <h1 class="title is-3 has-text-centered">
                {{ t('sign_in.header.welcome') }}
              </h1>

              <div
                v-if="error"
                class="notification is-danger"
              >
                <strong>{{ t(error) }}</strong>
              </div>

              <form
                v-if="state.mode === Mode.LOGIN_USERNAME_PASSWORD"
                class="username-password"
                @submit.prevent="submitUsernamePassword"
              >
                <h3 class="subtitle is-4 has-text-centered">
                  {{ t('sign_in.header.sign_in') }}
                </h3>

                <div class="field">
                  <label class="label">{{ t('sign_in.label.email_address') }}</label>
                  <div class="control">
                    <input
                      type="text"
                      class="input"
                      :value="form.email"
                      @input="setEmail($event.target.value)"
                    >
                  </div>
                </div>
                <div class="field">
                  <label class="label">{{ t('sign_in.label.password') }}</label>
                  <div class="control">
                    <input
                      type="password"
                      class="input"
                      :value="form.password"
                      @input="setPassword($event.target.value)"
                    >
                  </div>
                </div>
                <div class="buttons is-mobile is-right mt-4">
                  <button
                    type="submit"
                    class="button is-primary"
                    :class="{ 'is-loading': signingIn }"
                    :disabled="signingIn"
                  >
                    {{ t('sign_in.button.sign_in') }}
                  </button>
                </div>

                <div class="has-text-centered">
                  <a
                    class="is-underlined"
                    @click="state.mode = Mode.NO_CREDENTIALS"
                  >
                    {{ t('sign_in.link.no_credentials') }}
                  </a>
                </div>
              </form>

              <div v-if="state.mode === Mode.NO_CREDENTIALS">
                <h3 class="subtitle is-4 has-text-centered">
                  {{ t('sign_in.header.no_credentials') }}
                </h3>
                <div class="block">
                  <component
                    :is="part.is"
                    v-for="(part, i) of noCredentialsMessageParts"
                    :key="i"
                    :href="part.href || null"
                    :class="Object.assign({}, { 'is-underlined': part.is === 'a' }, part.className ?? {})"
                  >
                    {{ part.content }}
                  </component>
                </div>

                <div
                  class="block"
                  @click="state.mode = Mode.LOGIN_USERNAME_PASSWORD"
                >
                  <a class="button is-primary is-outlined is-fullwidth is-hidden-desktop">{{ t('sign_in.button.close') }}</a>
                  <a class="button is-primary is-outlined is-pulled-right is-hidden-touch">{{ t('sign_in.button.close') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.x-overlay-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: white;

    /* backdrop-filter: blur(20px); */
    /* background-color: red; */
    /* opacity: 0.6; */

    z-index: 32;
}

.x-overlay-page-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;

    background-color: rgb(255 255 255 / 0.9);
    backdrop-filter: blur(4px);
}

.x-overlay-page-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.box {
    margin: 3em;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.box, .box > .columns {
    min-height: calc(100vh - 6em);
}

form.username-password {
    max-width: 30em;
    margin: auto;
}

.app-content-pane {
    padding: 3em;
}

.app-nowrap {
    white-space: nowrap;
}
</style>
