<script setup>
import { onBeforeUnmount, watch } from 'vue'
import { getUniqueElementId } from '../../../util/id'

const props = defineProps({
  visible: { type: Boolean, default: false },
  title: { type: String, default: null },
  showCancelButton: { type: Boolean, default: true },
  hideFooter: { type: Boolean, default: false }
})
const emit = defineEmits(['update:visible'])

const backgroundElementId = getUniqueElementId()

const close = () => { emit('update:visible', false) }

const clickHandler = e => {
  const backgroundElement = document.getElementById(backgroundElementId)
  if (e.target !== backgroundElement) {
    return
  }

  // close modal when clicking on modal background
  close()
}

watch(
  () => props.visible,
  visible => {
    if (visible) {
      document.addEventListener('click', clickHandler)
    } else {
      document.removeEventListener('click', clickHandler)
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => { document.removeEventListener('click', clickHandler) })
</script>

<template>
  <div
    class="modal"
    :class="{ 'is-active': props.visible }"
  >
    <div
      :id="backgroundElementId"
      class="modal-background"
    />
    <div class="modal-card">
      <div class="modal-card-head">
        <p class="modal-card-title">
          {{ props.title }}
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="close"
        />
      </div>
      <section class="modal-card-body">
        <slot name="body" />
      </section>
      <footer
        v-if="!props.hideFooter"
        class="modal-card-foot"
      >
        <div class="buttons is-right">
          <button
            v-if="props.showCancelButton"
            class="button is-text has-text-grey"
            type="button"
            @click="close"
          >
            {{ t('modal.buttons.cancel') }}
          </button>
          <slot name="footer-buttons" />
        </div>
      </footer>
    </div>
  </div>
</template>
