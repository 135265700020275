import { computed } from 'vue'
import { getISOWeek, startOfISOWeek, parseISO, addDays, formatISO, addMonths } from 'date-fns'
import { firstDayOfYearMonth, addYearMonth } from '../../../util/calendar'

export function useCalendarComponent (props) {
  const weeks = computed(() => {
    const firstDayOfMonth = parseISO(props.currentMonth.year + '-' + (props.currentMonth.month < 10 ? '0' : '') + props.currentMonth.month + '-01T00:00:00')
    const firstWeekNumber = getISOWeek(firstDayOfMonth)
    const firstDayOfWeek = startOfISOWeek(firstDayOfMonth)

    let calendarDay = firstDayOfWeek

    const weeks = []
    for (const w of [1, 2, 3, 4, 5, 6]) {
      const week = {
        number: firstWeekNumber + (w - 1),
        days: []
      }
      for (const d of [1, 2, 3, 4, 5, 6, 7]) {
        week.days.push({
          number: d,
          day: calendarDay.getDate(),
          inMonth: (calendarDay.getMonth() + 1) === props.currentMonth.month && calendarDay.getFullYear() === props.currentMonth.year,
          date: formatISO(calendarDay, { representation: 'date' })
        })
        calendarDay = addDays(calendarDay, 1)
      }
      weeks.push(week)
    }

    return weeks
  })

  const cells = computed(() => {
    const c = []
    for (const week of weeks.value) {
      c.push({
        label: Number(week.number),
        type: 'week_number',
        id: 'week.' + week.number
      })
      for (const day of week.days) {
        c.push({
          label: Number(day.day),
          type: 'day',
          inMonth: day.inMonth,
          id: day.date,
          date: day.date,
          hasEvent: props.eventDates.includes(day.date)
        })
      }
    }
    return c
  })

  const startDate = computed(() => props.startMonth ? firstDayOfYearMonth(props.startMonth) : null)
  const endDateExclusive = computed(() => props.endMonth ? firstDayOfYearMonth(addYearMonth(props.endMonth, 1)) : null)

  const hasPrevMonth = computed(() => !props.startMonth || firstDayOfYearMonth(addYearMonth(props.currentMonth, -1)) >= startDate.value)
  const hasNextMonth = computed(() => !props.endMonth || firstDayOfYearMonth(addYearMonth(props.currentMonth, 1)) < endDateExclusive.value)

  const activeDatePrevMonth = computed(() => props.activeDate ? formatISO(addMonths(parseISO(props.activeDate), -1), { representation: 'date' }) : null)
  const activeDateNextMonth = computed(() => props.activeDate ? formatISO(addMonths(parseISO(props.activeDate), 1), { representation: 'date' }) : null)

  const prevYearMonth = computed(() => addYearMonth(props.currentMonth, -1))
  const nextYearMonth = computed(() => addYearMonth(props.currentMonth, 1))

  return {
    cells,
    startDate,
    endDateExclusive,
    hasPrevMonth,
    hasNextMonth,
    activeDateNextMonth,
    activeDatePrevMonth,
    prevYearMonth,
    nextYearMonth
  }
}
