export const validYearMonth = (ym) => {
  return typeof ym.year === 'number' &&
        typeof ym.month === 'number' &&
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(ym.month)
}

export const addYearMonth = (ym, monthsToAdd) => {
  const r = {
    year: ym.year + (monthsToAdd < 0 ? Math.ceil(monthsToAdd / 12) : Math.floor(monthsToAdd / 12)),
    month: ym.month + (monthsToAdd % 12)
  }

  if (r.month < 1) {
    r.month += 12
    r.year -= 1
  } else if (r.month > 12) {
    r.month -= 12
    r.year += 1
  }

  return r
}

export const firstDayOfYearMonth = ym => ym.year + '-' + (ym.month < 10 ? '0' : '') + ym.month + '-01'

export const validDate = d => d && typeof d === 'string' && d.match(/^\d{4}-(?:0\d|1[012])-(?:[012]\d|30|31)/)
