import { ref, computed } from 'vue'
import { signInUsernamePassword } from '../api/sign-in'
import { UnauthorizedError } from '../api/UnauthorizedError'
import { setUser } from './user'

const state = ref({
  signingIn: false,
  error: null,
  form: {
    email: '',
    password: ''
  }
})

// form
export const form = computed(() => state.value.form)
export const signingIn = computed(() => state.value.signingIn)
export const error = computed(() => state.value.error)
export const setEmail = email => { state.value.form.email = email }
export const setPassword = password => { state.value.form.password = password }

export const signInWithUsernamePassword = () => {
  state.value.signingIn = true
  state.value.error = null

  return signInUsernamePassword(state.value.form.email, state.value.form.password)
    .then(data => {
      setUser(
        data.email,
        data.display_name,
        data.customers,
        data.is_admin
      )

      state.value.form.email = ''
      state.value.form.password = ''
    })
    .catch(e => {
      if (e instanceof UnauthorizedError) {
        state.value.error = 'sign_in.error.unauthorized'
      } else {
        state.value.error = 'sign_in.error.unknown_error'
      }
    })
    .finally(() => { state.value.signingIn = false })
}
