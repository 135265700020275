import { ref, computed } from 'vue'
import { getUser, signOut as apiSignOut } from '../api/sign-in'

const state = ref({
  user: {
    signedIn: false,
    email: null,
    displayName: null,
    customers: [],
    customerId: null,
    isAdmin: false
  },
  signingOut: false
})

// user
export const user = computed(() => state.value.user)
export const signedIn = computed(() => state.value.user.signedIn)
export const signingOut = computed(() => state.value.signingOut)
export const customerId = computed(() => state.value.user.customerId)
export const customers = computed(() => state.value.user.customers)
export const customerUrlSlug = computed(() => (state.value.user.customers.find(c => c.id === state.value.user.customerId) || null)?.url_slug)
export const isAdmin = computed(() => signedIn.value && user.value.isAdmin)

export const setUser = (email, displayName, customers, isAdmin) => {
  state.value.user.signedIn = true
  state.value.user.email = email
  state.value.user.displayName = displayName
  state.value.user.customers = customers
  state.value.user.customerId = (customers.length === 1 ? customers.find(() => true).id : null)
  state.value.user.isAdmin = isAdmin
}

export const unsetUser = () => {
  state.value.user.signedIn = false
  state.value.user.email = null
  state.value.user.displayName = null
  state.value.user.customers = []
  state.value.user.customerId = null
  state.value.user.isAdmin = false
}

export const setCustomerId = (customerId) => {
  if (state.value.user.signedIn && state.value.user.customers.find(c => c.id === customerId)) {
    state.value.user.customerId = customerId
  }
}

export const setCustomerByUrlSlug = (urlSlug) => {
  const customer = state.value.user.customers.find(c => c.url_slug === urlSlug) || null
  if (customer) {
    state.value.user.customerId = customer.id
  } else {
    state.value.user.customerId = null
  }
}

export const signOut = async () => {
  state.value.signingOut = true
  try {
    await apiSignOut()
    unsetUser()
  } finally {
    state.value.signingOut = false
  }
}

export const fetchUser = async () => {
  try {
    const data = await getUser()
    setUser(data.email, data.display_name, data.customers, data.is_admin)
  } catch (e) {
    unsetUser()
  }
}
