<script setup>
import { watch } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { useI18n } from 'petite-vue-i18n'
import { validYearMonth, validDate } from '../../../util/calendar'
import { useCalendarComponent } from './calendar-component'

const props = defineProps({
  currentMonth: { type: Object, required: true, validator (ym) { return validYearMonth(ym) } },
  startMonth: { type: Object, default: null, validator (ym) { return ym === null || validYearMonth(ym) } },
  endMonth: { type: Object, default: null, validator (ym) { return ym === null || validYearMonth(ym) } },
  activeDate: { type: String, default: null, validator (d) { return d === null || d.match(/^\d{4}-(0\d|1[012])-([012]\d|3[01])$/) } },
  eventDates: { type: Array, default: () => [], validator (dates) { return dates.every(d => validDate(d)) } }
})
const emit = defineEmits(['update:active-date', 'update:current-month'])
const { t } = useI18n()
const { cells, hasPrevMonth, hasNextMonth, startDate, endDateExclusive, activeDatePrevMonth, activeDateNextMonth, prevYearMonth, nextYearMonth } = useCalendarComponent(props)

const onPrevMonth = () => {
  if (!hasPrevMonth.value) {
    return
  }

  if (props.activeDate) {
    emit('update:active-date', activeDatePrevMonth.value)
  } else {
    emit('update:current-month', prevYearMonth.value)
  }
}

const onNextMonth = () => {
  if (!hasNextMonth.value) {
    return
  }

  if (props.activeDate) {
    emit('update:active-date', activeDateNextMonth.value)
  } else {
    emit('update:current-month', nextYearMonth.value)
  }
}

watch(
  () => props.activeDate,
  activeDate => {
    if (!activeDate) {
      return
    }

    const currentMonth = { year: parseInt(activeDate.substring(0, 4)), month: parseInt(activeDate.substring(5, 7)) }
    emit('update:current-month', currentMonth)
  }
)
</script>

<template>
  <div>
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <button
            class="button"
            :disabled="!hasPrevMonth"
            @click="onPrevMonth"
          >
            <span class="icon">
              <FontAwesomeIcon :icon="faAngleLeft" />
            </span>
          </button>
        </div>
      </div>
      <div class="level-item">
        <strong>
          {{ t('planning.calendar.header.month.' + props.currentMonth.month) }}
          {{ props.currentMonth.year }}
        </strong>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button
            class="button"
            :disabled="!hasNextMonth"
            @click="onNextMonth"
          >
            <span class="icon">
              <FontAwesomeIcon :icon="faAngleRight" />
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="fixed-grid has-8-cols">
      <div class="grid">
        <div class="cell" />
        <div class="cell">
          {{ t('planning.calendar.header.monday') }}
        </div>
        <div class="cell">
          {{ t('planning.calendar.header.tuesday') }}
        </div>
        <div class="cell">
          {{ t('planning.calendar.header.wednesday') }}
        </div>
        <div class="cell">
          {{ t('planning.calendar.header.thursday') }}
        </div>
        <div class="cell">
          {{ t('planning.calendar.header.friday') }}
        </div>
        <div class="cell">
          {{ t('planning.calendar.header.saturday') }}
        </div>
        <div class="cell">
          {{ t('planning.calendar.header.sunday') }}
        </div>
        <div
          v-for="cell of cells"
          :key="cell.id"
          class="cell is-relative"
          :class="{ 'has-background-dark' : cell.date === props.activeDate, 'has-background-white-bis': cell.date !== props.activeDate && cell.hasEvent }"
          :title="cell.date"
        >
          <span
            v-if="cell.type === 'week_number'"
            class="has-text-grey"
          >
            {{ cell.label }}
          </span>
          <a
            v-if="cell.type === 'day' && (!startDate || startDate <= cell.date) && (!endDateExclusive || endDateExclusive > cell.date)"
            class="is-overlay"
            :class="{ 'is-underlined': cell.hasEvent, 'has-text-grey-lighter': cell.date !== props.activeDate && !cell.inMonth, 'has-text-white-ter': cell.date === props.activeDate }"
            @click="emit('update:active-date', cell.date)"
          >
            <strong v-if="cell.hasEvent">{{ cell.label }}</strong>
            <span v-if="!cell.hasEvent">{{ cell.label }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.app-week-number {
    width: 2em;
    color: var(--bulma-)
}

.cell {
    text-align: center;
}
</style>
