import HomeView from './components/view/HomeView.vue'
import InstallationsView from './components/view/InstallationsView.vue'
import PlanningView from './components/view/PlanningView.vue'
import SignInView from './components/view/SignInView.vue'
import SwitchCustomerView from './components/view/SwitchCustomerView.vue'

export const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/installations/:customerUrlSlug',
    name: 'installations',
    component: InstallationsView,
    props: true
  },
  {
    path: '/planning/:customerUrlSlug',
    name: 'planning',
    component: PlanningView,
    props: true
  },
  {
    path: '/sign-in',
    name: 'sign_in',
    component: SignInView
  },
  {
    path: '/switch-customer',
    name: 'switch_customer',
    component: SwitchCustomerView
  },
  {
    path: '/administer-users',
    name: 'administer_users',
    component: () => import('./components/view/AdministerUsersView.vue')
  }
]
