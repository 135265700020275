<script setup>
import { onMounted, ref, computed, watch } from 'vue'
import { getInstallations, postExport } from '../../api/installations'
import MapComponent from '../common/map/MapComponent.vue'
import FilterComponent from '../common/installations/FilterComponent.vue'
import InstallationsList from '../common/installations/InstallationsList.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'
import { setCustomerByUrlSlug, customerId } from '../../store/user'
import { getUniqueElementId } from '../../util/id'
import { useI18n } from 'petite-vue-i18n'
import mitt from 'mitt'

const props = defineProps({
  customerUrlSlug: { type: String, required: true }
})

const state = ref({
  installations: [],
  selectedWorkAreaId: null,
  selectedStatuses: ['maintenance'],
  containerId: getUniqueElementId(),
  exporting: false
})

const { t } = useI18n()

const mapEventBus = mitt()

onMounted(() => {
  if (!customerId.value) {
    return
  }

  getInstallations(customerId.value)
    .then(installations => {
      state.value.installations = installations
    })
})

const workAreas = computed(() => {
  const workAreaIds = []
  const workAreas = []
  state.value.installations.forEach(i => {
    if (workAreaIds.includes(i.work_area.id)) {
      return
    }
    workAreaIds.push(i.work_area.id)
    workAreas.push(i.work_area)
  })
  return workAreas
})

const filteredInstallations = computed(() => {
  if (!state.value.selectedWorkAreaId && state.value.selectedStatuses.length === 0) {
    return state.value.installations
  }

  return state.value.installations
    .filter(i => state.value.selectedWorkAreaId === null || i.work_area.id === state.value.selectedWorkAreaId)
    .filter(i => state.value.selectedStatuses.length === 0 || state.value.selectedStatuses.includes(i.status))
})

const markers = computed(() => {
  return filteredInstallations.value
    .filter(installation => installation.position !== null)
    .map(installation => ({
      lat: installation.position.lat,
      long: installation.position.long,
      label: installation.record_number || installation.serial,
      id: installation.id
    }))
})

const onExport = () => {
  state.value.exporting = true
  postExport(customerId.value, state.value.selectedWorkAreaId, state.value.selectedStatuses)
    .then(blob => {
      const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const file = new File([blob], 'installations.xlsx', { type: mimeType })
      const url = window.URL.createObjectURL(file)
      window.location.assign(url)
    })
    .catch(e => { console.log('Error exporting data: ' + e) })
    .finally(() => { state.value.exporting = false })
}

watch(
  () => props.customerUrlSlug,
  urlSlug => setCustomerByUrlSlug(urlSlug),
  { immediate: true }
)
</script>

<template>
  <div
    class="app-view-container"
  >
    <div>
      <div>
        <MapComponent
          :markers="markers"
          :event-bus="mapEventBus"
        />
      </div>
      <div :id="state.containerId">
        <section class="p-0">
          <div class="container">
            <div class="is-relative">
              <FilterComponent
                v-model:selectedWorkAreaId="state.selectedWorkAreaId"
                v-model:selectedStatuses="state.selectedStatuses"
                :work-area-options="workAreas"
                class="block"
              />

              <button
                class="button is-small"
                :class="{ 'is-loading': state.exporting }"
                style="position: absolute; bottom: 1rem; right: 1rem;"
                :disabled="state.exporting"
                @click="onExport"
              >
                <span class="icon is-small"><FontAwesomeIcon :icon="faFileExport" /></span>
                <span>{{ t('installations.button.export') }}</span>
              </button>
            </div>

            <InstallationsList
              :installations="filteredInstallations"
              :map-event-bus="mapEventBus"
              :scroll-parent-element-id="state.containerId"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style scoped>
.app-view-container, .app-view-container > div {
    height: 100%;
}

@media (min-width: 769px) {
    .app-view-container > div {
        display: flex;
    }
    .app-view-container > div > div {
        flex-grow: 1;
        height: 100%;
        flex-basis: 0;
    }
    .app-view-container > div > div:nth-child(2) {
        flex-shrink: 0;
        max-width: 600px;
        min-width: 400px;

        height: calc(100vh - 96px);
        height: calc(100dvh - 96px);
        overflow-y: scroll;
    }
}
@media (max-width: 768px) {
    .app-view-container > div > div:nth-child(1) {
        min-height: calc(50vh - 116px);
        min-height: calc(50dvh - 116px);
        max-height: min(600px, calc(75vh - 116px));
        height: 100%;
    }

    .app-view-container, .app-view-container > div {
      height: calc(100vh - 116px);
      height: calc(100dvh - 116px);
      overflow-y: auto;
      overflow-x: hidden;
    }
}
</style>
