<script setup>
import { computed, ref, watch } from 'vue'
import ModalCard from '../modal/ModalCard.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { useI18n } from 'petite-vue-i18n'

const { t } = useI18n()

const props = defineProps({
  installation: { type: Object, default: null },
  visible: { type: Boolean, default: false }
})

const state = ref({
  photoIndex: 0
})

const emit = defineEmits(['update:visible'])

const title = computed(() => {
  if (!props.installation) {
    return ''
  }

  return props.installation.work_area.name +
        ' ' +
        props.installation.record_number +
        (props.serial ? ' \u2022 ' + props.serial : '')
})

const hasPhotos = computed(() => props.installation?.photos?.length > 0)

const installationPhotoUrl = computed(() => {
  if (!props.installation || !props.installation.photos) {
    return null
  }

  if (props.installation.photos.length <= state.value.photoIndex) {
    return '/assets/installation-placeholder.png'
  }

  return '/api/installations/photo/' + encodeURIComponent(props.installation.photos[state.value.photoIndex].id) + '/web'
})

const hasMore = computed(() => props.installation ? props.installation.photos.length > 1 : false)
const onPrev = () => { state.value.photoIndex = state.value.photoIndex === 0 ? Math.max(0, props.installation.photos.length - 1) : state.value.photoIndex - 1 }
const onNext = () => { state.value.photoIndex = props.installation.photos.length === 0 ? 0 : (state.value.photoIndex + 1) % props.installation.photos.length }

const description = computed(() => {
  const parts = []

  if (props.installation.cabinet_position) {
    if (props.installation.cabinet_position === 'Hangend') {
      parts.push(t('installations.cabinet_position.hanging'))
    } else if (props.installation.cabinet_position === 'Liggend') {
      parts.push(t('installations.cabinet_position.laying'))
    }
    if (props.installation.cabinet_position === 'Staand') {
      parts.push(t('installations.cabinet_position.standing'))
    }
  }

  if (props.installation.head_count > 0) {
    parts.push(
      (props.installation.head_count === 1 ? t('installations.head_count_1') : t('installations.head_count_n'))
        .replace('%count%', String(props.installation.head_count))
    )
  }
  if (props.installation.pipe_count > 0) {
    parts.push(
      (props.installation.pipe_count === 1 ? t('installations.pipe_count_1') : t('installations.pipe_count_n'))
        .replace('%count%', String(props.installation.pipe_count))
    )
  }

  return parts.join(', ')
})

watch(() => props.visible, () => { state.value.photoIndex = 0 })
watch(() => props.installation, () => { state.value.photoIndex = 0 })
</script>

<template>
  <ModalCard
    :title="title"
    :show-cancel-button="false"
    :visible="props.visible"
    hide-footer
    @update:visible="emit('update:visible', $event)"
  >
    <template #body>
      <template v-if="props.installation">
        <div
          v-if="hasPhotos"
          class="is-relative"
        >
          <img
            :src="installationPhotoUrl"
            class="app-modal-image"
          >
        </div>

        <template v-if="hasMore">
          <button
            class="button is-rounded is-dark app-photo-prev"
            type="button"
            @click="onPrev"
          >
            <span class="icon is-small">
              <FontAwesomeIcon :icon="faAngleLeft" />
            </span>
          </button>
          <button
            class="button is-rounded is-dark app-photo-next"
            type="button"
            @click="onNext"
          >
            <span class="icon is-small">
              <FontAwesomeIcon :icon="faAngleRight" />
            </span>
          </button>
        </template>

        <p
          v-if="!hasPhotos"
          class="has-text-centered"
        >
          {{ t('installations.detail.no_photo_available') }}
        </p>

        <p
          class="has-text-centered"
          :class="{ 'pt-4': !hasPhotos }"
        >
          {{ description }}
        </p>
      </template>
    </template>
  </ModalCard>
</template>

<style scoped>
.app-modal-image {
    display: block;
    width: 100%;
    max-height: 60vh;
    max-height: 60dvh;
    object-fit: contain;
}

.app-photo-prev {
    position: absolute;
    left: 1em;
    top: 50%;
}

.app-photo-next {
    position: absolute;
    right: 1em;
    top: 50%;
}
</style>
