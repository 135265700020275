import hierarchicalMessages from './messages.json'

// flattens hierarchical message structure to use with petite-vue-i18n
const flatten = (item, prefix, carry) => {
    if (typeof item === 'string') {
        carry[prefix] = item
        return
    }

    for (const key in item) {
        flatten(item[key], (prefix ? prefix + '.' + key : key), carry)
    }

    return carry
}

const messages = (() => {
    const messages = {}
    for (const lang in hierarchicalMessages) {
        messages[lang] = flatten(hierarchicalMessages[lang], null, {})
    }

    return messages
})()

export default messages