<script setup>
import { getUniqueElementId } from '../../../util/id'
import { ref, onBeforeMount, onMounted, onBeforeUnmount, watch } from 'vue'
import InstallationListItem from './InstallationListItem.vue'
import InstallationModal from './InstallationModal.vue'

const props = defineProps({
  installations: { type: Array, default: () => [] },
  mapEventBus: { type: Object, required: true },
  scrollParentElementId: { type: String, required: true }
})

const state = ref({
  installationRowBaseId: getUniqueElementId(),
  timeOutId: null,
  recentTimestamp: null,
  modalInstallation: null,
  modalVisible: false
})

const getRowElement = (installationId) => document.getElementById(state.value.installationRowBaseId + '-' + installationId)

const onMapMarkerClick = id => {
  if (!id) {
    return
  }

  const element = getRowElement(id)
  if (element) {
    document.getElementById(props.scrollParentElementId).scrollTop = element.offsetTop
  }
}

let hoverId = null
const onMapMarkerHover = id => {
  if (id === hoverId) {
    return
  }

  if (hoverId) {
    const elem = getRowElement(hoverId)
    if (elem) {
      elem.classList.remove('app-has-yellow-background-light')
    }
  }

  if (id) {
    const elem = getRowElement(id)
    if (elem) {
      elem.classList.add('app-has-yellow-background-light')
    }
  }

  hoverId = id
}

onBeforeMount(() => { onTick() })
onMounted(() => {
  props.mapEventBus.on('marker-click', onMapMarkerClick)
  props.mapEventBus.on('marker-hover', onMapMarkerHover)
  onTick()
})
onBeforeUnmount(() => {
  props.mapEventBus.off('marker-click', onMapMarkerClick)
  props.mapEventBus.off('marker-hover', onMapMarkerHover)
  if (state.value.timeOutId) {
    window.clearTimeout(state.value.timeOutId)
  }
})

const onTick = () => {
  const date = new Date()
  date.setDate(date.getDate() - 1)

  state.value.recentTimestamp = date.getFullYear() +
    '-' + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) +
    '-' + (date.getDate() < 10 ? '0' : '') + date.getDate() +
    'T' + (date.getHours() < 10 ? '0' : '') + date.getHours() +
    ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() +
    ':' + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()

  state.value.timeOutId = window.setTimeout(onTick, 60000)
}

const onSelected = installation => {
  state.value.modalInstallation = installation
  state.value.modalVisible = true
}

watch(
  () => state.value.modalVisible,
  visible => {
    if (!visible) {
      state.value.modalInstallation = null
    }
  }
)
</script>

<template>
  <div class="list">
    <InstallationListItem
      v-for="installation of installations"
      :id="state.installationRowBaseId + '-' + installation.id"
      :key="installation.id"
      :installation="installation"
      :recent-timestamp="state.recentTimestamp"
      @selected="onSelected(installation)"
    />
  </div>
  <InstallationModal
    v-model:visible="state.modalVisible"
    :installation="state.modalInstallation"
  />
</template>
