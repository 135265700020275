<script setup>
import { watch } from 'vue'
import AppNav from './components/common/AppNav.vue'
import { signedIn, customerId, customerUrlSlug, isAdmin, unsetUser } from './store/user'
import { useRouter } from 'vue-router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAt, faPhone } from '@fortawesome/free-solid-svg-icons'

const router = useRouter()

let rerouted = false

watch(
  () => signedIn.value ? 's' + (customerId.value ? ':' + customerId.value : '') : '',
  () => {
    const route = document.documentElement.getAttribute('data-route')
    if (signedIn.value && customerId.value) {
      if (!rerouted) {
        // hack, not sure why redirect is necessary
        if (route === 'installations' && customerUrlSlug.value) {
          router.replace({ name: route, params: { customerUrlSlug: customerUrlSlug.value } })
        }
        if (route === 'planning' && customerUrlSlug.value) {
          router.replace({ name: route, params: { customerUrlSlug: customerUrlSlug.value } })
        }

        if (route === 'administer_users') {
          router.replace({ name: route })
        }

        rerouted = true
      } else {
        if (route.path === '/') {
          router.replace({ name: route, customerUrlSlug: customerUrlSlug.value })
        }
      }
    } else if (signedIn.value && route === 'administer-users') {
      if (!isAdmin.value) {
        unsetUser()
        router.push({ name: 'sign_in' })
      } else {
        // do nothing
      }
    } else if (signedIn.value && !customerId.value) {
      router.push({ name: 'switch_customer' })
    } else {
      router.push({ name: 'sign_in' })
    }
  },
  { immediate: true }
)
</script>

<template>
  <div class="is-flex is-flex-direction-column app-page">
    <template v-if="signedIn">
      <AppNav />
    </template>

    <div class="app-content is-flex-grow-1">
      <router-view />
    </div>

    <div class="app-footer">
      <footer class="footer pt-2 pb-2 has-background-grey">
        <div class="container has-text-centered">
          <a
            class="icon-text is-underlined has-text-white"
            href="mailto:support@railpartnerholland.com"
          >
            <span class="icon">
              <FontAwesomeIcon :icon="faAt" />
            </span>
            <span>support@railpartnerholland.com</span>
          </a>
          <span class="pl-4 pr-4 is-hidden-touch" />
          <br class="is-hidden-tablet">
          <span class="is-hidden-mobile p-4"><!-- spacer --></span>
          <a
            class="icon-text is-underlined has-text-white"
            href="tel:0031416321515"
          >
            <span class="icon">
              <FontAwesomeIcon :icon="faPhone" />
            </span>
            <span>+31 (0) 416 32 15 15</span>
          </a>
        </div>
      </footer>
    </div>
  </div>
</template>

<style scoped>
.app-page {
    min-height: 100vh;
}

.app-footer {
  font-size: 90%;
}
</style>
