<script setup>
import Map from 'ol/Map.js'
import OSM from 'ol/source/OSM.js'
import TileLayer from 'ol/layer/Tile.js'
import LayerVector from 'ol/layer/Vector'
import SourceVector from 'ol/source/Vector'
import SourceCluster from 'ol/source/Cluster'
import View from 'ol/View'
import { watch, onMounted } from 'vue'
import { getUniqueElementId } from '../../../util/id'

import { createMarker, getBoundingExtent, getClusterStyles, clusterDistance, markerOrientation } from '../../../util/ol/openlayer'

const props = defineProps({
  markers: { type: Array, default: () => [] },
  eventBus: { type: Object, required: true }
})

const state = {
  map: null,
  markersVector: null,
  clusterSource: null,
  clustersVector: null
}

const mapId = getUniqueElementId()

function clusterStyle (feature) {
  const size = feature.get('features').length
  return getClusterStyles(size)
}

onMounted(() => {
  state.map = new Map({
    target: mapId,
    layers: [
      new TileLayer({
        source: new OSM()
      })
    ],
    view: new View({
      center: [0, 0],
      zoom: 5
    })
  })

  state.markersVector = new LayerVector({
    source: new SourceVector(),
    minZoom: 12
  })

  state.clusterSource = new SourceCluster({
    distance: clusterDistance,
    source: state.markersVector.getSource()
  })
  state.clustersVector = new LayerVector({
    source: state.clusterSource,
    style: clusterStyle,
    maxZoom: 12
  })

  state.map.addLayer(state.markersVector)
  state.map.addLayer(state.clustersVector)

  state.map.on('pointermove', onMapPointerMove)
  state.map.on('click', onMapPointerClick)
})

const onMapPointerMove = evt => {
  if (evt.dragging) {
    return
  }

  const pixel = state.map.getEventPixel(evt.originalEvent)
  state.markersVector.getFeatures(pixel)
    .then(features => {
      const customId = features[0]?.getProperties().customId ?? null
      props.eventBus.emit('marker-hover', customId)
    })
}

const onMapPointerClick = evt => {
  if (evt.dragging) {
    return
  }

  const pixel = state.map.getEventPixel(evt.originalEvent)

  state.markersVector.getFeatures(pixel)
    .then(features => {
      for (const feature of features) {
        const customId = feature.getProperties().customId ?? null
        if (customId) {
          props.eventBus.emit('marker-click', customId)
          break
        }
      }
    })
}

watch(
  () => props.markers,
  markers => {
    state.markersVector.getSource().clear()

    const nonEmpty = markers.filter(({ lat, long }) => lat !== '' && long !== '' && lat !== null && long !== null)

    const position = {}
    nonEmpty.forEach(({ lat, long }) => {
      position[lat] = position[lat] || {}
      position[lat][long] = 0
    })

    const getOrientation = (lat, long) => {
      const orientation = [markerOrientation.TOP, markerOrientation.LEFT, markerOrientation.RIGHT][position[lat][long]]
      position[lat][long] = (position[lat][long] + 1) % 3
      return orientation
    }

    const features = nonEmpty.map(({ lat, long, label, id }) => createMarker(lat, long, label, id, getOrientation(lat, long)))
    state.markersVector.getSource().clear(true)
    state.markersVector.getSource().addFeatures(features)

    if (nonEmpty.length) {
      const extent = getBoundingExtent(nonEmpty)
      const map = state.map
      map.getView().fit(extent)
      map.getView().setZoom(map.getView().getZoom() - 1)
    }
  }
)
</script>

<template>
  <div
    :id="mapId"
    class="map"
  />
</template>

<style scoped>
.map {
    width: 100%;
    height: 100%;
}
</style>
