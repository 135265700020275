import { fetchGet, fetchPostJson } from './fetch'

export const getInstallations = async (customerId) => {
  const url = '/api/installations/' + encodeURIComponent(customerId)
  return await fetchGet(url)
}

export const postExport = async (customerId, workAreaId, statuses) => {
  const url = '/api/installations/' + encodeURIComponent(customerId) + '/export'
  return await fetchPostJson(url, { work_area_id: workAreaId, statuses })
}
