import { ForbiddenError } from './ForbiddenError'
import { UnauthorizedError } from './UnauthorizedError'

const validateResponse = response => {
  if (response.status === 204) {
    return Promise.resolve({})
  } else if (response.status === 200) {
    if (response.headers.get('Content-Type') === 'application/json') {
      return response.json()
    }
    return response.blob()
  } else if (response.status === 401) {
    throw new UnauthorizedError()
  } else if (response.status === 403) {
    throw new ForbiddenError()
  }

  throw new Error('received unexpected response code ' + response.status)
}

export const fetchGet = (url) => {
  return fetch(url)
    .then(validateResponse)
}

export const fetchPost = (url, body, headers) => {
  return fetch(url, { method: 'POST', body, headers: headers === undefined ? {} : headers })
    .then(validateResponse)
}

export const fetchPostJson = (url, data) => {
  return fetchPost(url, JSON.stringify(data), { 'Content-Type': 'application/json' })
}

export const fetchDelete = (url) => {
  return fetch(url, { method: 'DELETE' })
    .then(validateResponse)
}

export const fetchPatch = (url, body, headers) => {
  return fetch(url, { method: 'PATCH', body, headers: headers === undefined ? {} : headers })
    .then(validateResponse)
}

export const fetchPatchJson = (url, data) => {
  return fetchPatch(url, JSON.stringify(data), { 'Content-Type': 'application/json' })
}
