import { fetchPostJson, fetchPost, fetchGet } from './fetch'

export const signInUsernamePassword = (username, password) => {
  return fetchPostJson('/api/account/sign-in', { username, password })
}

export const signOut = async () => {
  return await fetchPost('/api/account/sign-out')
}

export const getUser = () => {
  return fetchGet('/api/account/user')
}
