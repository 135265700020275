import { createApp } from 'vue'
import '../style/style.scss'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes.js'
import { createI18n } from 'petite-vue-i18n'
import messages from '../translations/messages'
import { fetchUser, setCustomerId } from './store/user.js'

const init = () => {
  const i18n = createI18n({
    locale: 'nl',
    messages
  })

  const router = createRouter({
    history: createWebHistory(),
    routes
  })

  createApp(App)
    .use(i18n)
    .use(router)
    .mount('#app')
}

const signedIn = document.documentElement.getAttribute('data-signed-in') === 'true'
const customerId = document.documentElement.getAttribute('data-customer-id')
if (signedIn) {
  fetchUser()
    .then(() => {
      if (customerId) {
        setCustomerId(parseInt(customerId))
      }
    })
    .finally(() => init())
} else {
  init()
}
