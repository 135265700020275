<script setup>
import { useI18n } from 'petite-vue-i18n'
import { customers, setCustomerId, customerUrlSlug } from '../../store/user'
import { useRouter } from 'vue-router'
import { nextTick } from 'vue'

const { t } = useI18n()
const router = useRouter()

const onCustomer = id => {
  setCustomerId(id)
  if (customerUrlSlug.value) {
    nextTick(() => {
      router.push({ name: 'installations', params: { customerUrlSlug: customerUrlSlug.value } })
    })
  }
}
</script>

<template>
  <section class="section">
    <div class="container">
      <h1 class="title is-1">
        {{ t('switch_customer.page_title') }}
      </h1>

      <div class="menu">
        <ul class="menu-list">
          <li
            v-for="customer of customers"
            :key="customer.id"
          >
            <a
              class="is-underlined"
              @click="onCustomer(customer.id)"
            >
              {{ customer.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style scoped>
section.section {
  height: calc(100vh - 112px);
  height: calc(100dvh - 112px);
  overflow-y: scroll;
}

@media(max-width: 768px) {
  section.section {
    height: calc(100vh - 132px);
    height: calc(100dvh - 132px);
  }
}
</style>
